import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  imageWrapper: {
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    height: 300,
    transition: 'all .2s',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:hover': {
      transform: 'scale(1.025)'
    },
    [theme.breakpoints.up('md')]: {
      height: 400,
    },
    [theme.breakpoints.up('lg')]: {
      height: 555,
    }
  },
  introHeader: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
    lineHeight: 1.33,
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4.5),
      fontSize: 48,
      lineHeight: 1.15
    }
  },
  introCopy: {
    fontSize: 13,
    lineHeight: 1.5,
    marginBottom: theme.spacing(24),
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      marginBottom: theme.spacing(42)
    },
  },
  brandContainer: {
    borderRadius: theme.shape.borderRadius, 
    backgroundColor: 'white', 
    height: 86,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    [theme.breakpoints.up('md')]: {
      height: 200
    }
  },
  infoHeader: {
    fontSize: 24,
    lineHeight: 1.33,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
      lineHeight: 1.16,
      marginBottom: theme.spacing(4)
    }
  },
  infoCopy: {
    color: '#666',
    fontSize: 13,
    lineHeight: 1.5,
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      width: 536,
      textAlign: 'left'
    }
  },
  orderReceivedContainer: {
    backgroundColor: theme.palette.common.white,
    width: 225,
    height: 275,
    borderRadius: theme.spacing(4, 4, 0, 0),
    margin: 'auto',
    padding: theme.spacing(2.75, 2, 1.5),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: 475,
      height: 531,
      padding: theme.spacing(7.5, 6.5, 5),
    }
  },
  guestHouseLogo: {
    height: 24,
    width: 24,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]:{
      height: 48,
      width: 48,
      marginBottom: theme.spacing(8)
    }
  },
  whatsNextList: {
    fontSize: 8,
    lineHeight: 1.5,
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      fontSize: 16
    }
  },
  subHeroTextContainer: {
    backgroundColor: theme.palette.common.white,
    width: '80%',
    height: 300,
    borderRadius: theme.shape.borderRadius,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    top: 92,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3, 3),
    [theme.breakpoints.up('md')]: {
      top: 213,
      width: 872,
      padding: theme.spacing(9, 14)
    },
  },
  learnStagingButton: {
    marginTop: theme.spacing(1.5),
    width: 200,
    padding: theme.spacing(0.5),
    borderRadius: 5,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.5)
    },
  }
}));
