import makeStyles from '@ui/utils/makeStyles';


export default makeStyles()((theme) => ({
  heroWrapper: {
    width: '100%',
    maxWidth: theme.gh_vars.maxWidth,
    padding: 0,
    margin: '0 auto',
    position: 'relative',
    marginBottom: theme.spacing(6),
    height: 477,
    [theme.breakpoints.up('md')]: {
      height: 656,
    }
  },
  heroBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundPosition: 'center',
    zIndex: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundSize: 'cover',
  },
  heroTextContainer: {
    color: theme.palette.common.white,
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    top: 0,
    zIndex: 2,
    padding: theme.spacing(0, 3, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 20, 0)
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 31.5, 0)
    },
  },
  heroText: {
    fontSize: 36,
    lineHeight: 1.15,
    fontWeight: 500,
    [theme.breakpoints.up('md')]: {
      fontSize: 72
    }
  },
  heroOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: .75,
    backgroundImage: 'linear-gradient(to bottom, rgba(8, 8, 8, 0) 100%, #080808)',
    zIndex: 1,
  },
  textField: {
    fontSize: 14,
    width: 'auto',
    flex: 1,
    '& input:placeholder': {
      color: '#666 !important'
    }
  },
  submitButton: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    top: 'calc(100% + 10px)',
    width: '100%',
    margin: 0,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      top: 'unset',
      borderRadius: 5,
      marginRight: 7,
      paddingLeft: 30,
      paddingRight: 30,
      width: 175,
    },
  },
  form: {
    maxWidth: 600,
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    height: 45,
    [theme.breakpoints.up('md')]: {
      height: 64,
      position: 'relative',
      top: 'unset',
      borderRadius: 5,
    },
  },
  startAdornment: {
    width: 22,
    height: 22,
    position: 'relative',
    bottom: 2,
    marginRight: theme.spacing(1),
    '& path': {
      fill: '#666',
    }
  },
}));
