import ButtonLink from '@components/button-link/ButtonLink';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

import useStyles from './HeroAlt.style';

const Hero: React.FC = () => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.heroWrapper}>

      <div className={classes.heroTextContainer}>
        <Typography
          variant="h1"
          className={classes.heroText}
          style={{ marginBottom: theme.spacing(5) }}
        >
          Beautiful staging 
          {' '}
          <br/>
          {' '}
          in days, not weeks.
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          width="100%"
        >
          <Button
            variant="contained"
            color="white"
            component={ButtonLink}
            href="/home-staging/get-a-quote"
            style={{
              marginRight: theme.spacing(2),
              width: 240,
              fontWeight: 'bold',
              backgroundColor: '#e8e8e8',
              color: '#333'
            }}
          >
            Get an instant quote
          </Button>
        </Box>
      </div>

      <div
        className={classes.heroBackground}
        data-bg-multi="radial-gradient(45.16% 45.16% at 50% 44.87%, rgba(8, 8, 8, 0.05) 0%, rgba(8, 8, 8, 0) 100%), radial-gradient(122.67% 468.02% at 36.8% 60.12%, rgba(8, 8, 8, 0.2) 0%, rgba(8, 8, 8, 0) 100%), url(/images/homepage/header.png)"
      />

    </div>
  );
};

export default Hero;
