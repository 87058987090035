import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Image from 'next/image';
import React from 'react';

interface PressLogosProps {
  className?: string;
  style?: React.CSSProperties;
}

const pressLogos = [
  { name: 'Apartmemt Therapy', logo: '/images/press/apartment-therapy.png', width: 252, height: 77,
    url: 'https://www.apartmenttherapy.com/fake-food-home-staging-36968505'
  },
  { name: 'Business Insider', logo: '/images/press/business-insider.png', width: 167, height: 52,
    url: 'https://www.businessinsider.com/guest-house-pitch-deck-home-staging-furniture-retail-startup-funding-2021-6'
  },
  { name: 'Dwell', logo: '/images/press/dwell.png', width: 152, height: 57,
    url: 'https://www.dwell.com/article/hannah-pobar-home-tour-bf5ba417'
  },
  { name: 'Luxe', logo: '/images/press/luxe.png', width: 166, height: 84,
    url: 'https://luxesource.com/thefourthhouse-denver-designer-bret-sundberg#.Y41hOezMKw2'
  },
  { name: 'Colorado Homes', logo: '/images/press/co-homes.png', width: 171, height: 78,
    url: 'https://www.coloradohomesmag.com/homes-you-can-shop/'
  },
  { name: 'San Diego Business Journal', logo: '/images/press/san-diego-business-journal.png', width: 280, height: 40,
    url: 'https://www.sdbj.com/news/enews/san-diego-native-bringing-home-staging-business-ho/'
  },
];

const PressLogos: React.FC<PressLogosProps> = (props) => {
  const { className, style } = props;

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      spacing={8}
      className={className}
      style={style}
    >
      {pressLogos.map(({ name, logo, height, url }) => (
        <Grid
          key={name}
          item
          xs={6}
          md={6}
          lg={4}
        >
          <Box
            display="flex"
            style={{
              overflow: 'hidden',
              height: height,
              width: '100%',
              position: 'relative',
              alignItems: 'center'
            }}
          >
            <a href={url}>
              <Image
                fill
                src={logo}
                alt={name}
                sizes="100vw"
                style={{
                  objectFit: 'contain'
                }}
              />
            </a>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default PressLogos;
