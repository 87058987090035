import AppBackgroundColor from '@components/app-background-color/AppBackgroundColor';
import ButtonLink from '@components/button-link/ButtonLink';
import HeroAlt from '@components/hero-alt/HeroAlt';
import PressLogos from '@components/press-logos/PressLogos';
import Sentry from '@integrations/Sentry';
import { ContentBlock } from '@layouts/ContentBlocks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import IconAffirm from '@ui/icons/affirm/black-white-bg.svg';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { LocalBusinessJsonLd } from 'next-seo';
import React from 'react';

import useStyles from './Homepage.style';

const jslonld = `{
  "@context": "https://schema.org/",
  "@type": "WebSite",
  "name": "Guest House",
  "url": "https://guesthouseshop.com/",
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://guesthouseshop.com/shop?query={search_term_string}",
    "query-input": "required name=search_term_string"
  }
}`;

const WebSiteJsonLd = () => {
  return (
    <Head>
      <script
        key="jsonld-website"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: jslonld }}
      />
    </Head>
  );
};

function Homepage() {
  const { theme } = useStyles();
  const { sm, md, lg } = useBreakpoints();
  const { classes } = useStyles();

  const brandLogos = [
    { name: 'Thuma', logo: '/images/brands/thuma-logo.png', width: md ? 200 : 140, height: md ? 160 : 86 },
    { name: 'Interior Define', logo: '/images/brands/interior-define-logo.png', width: md ? 200 : 140, height: md ? 160 : 86 },
    { name: 'Crate&Barrel', logo: '/images/brands/crate-and-barrel-logo.png', width: md ? 159 : 68, height: md ? 28 : 12 },
    { name: 'Public Goods', logo: '/images/brands/public-goods-logo.png', width: md ? 166 : 140, height: md ? 166 : 86 },
    { name: 'Lulu and Georgia', logo: '/images/brands/lulu-georgia-logo.png', width: md ? 200 : 140, height: md ? 200 : 86 },
  ];

  return (
    <>
      <AppBackgroundColor
        backgroundColor="#F7F7F7"
        borderColor="#F7F7F7"
        headerColor="#F7F7F7"
      />

      {WebSiteJsonLd()}

      <LocalBusinessJsonLd
        type="LocalBusiness"
        id=""
        name="Guest House"
        description=""
        url={makeStoreUrl()}
        telephone="858-254-2752"
        address={{
          streetAddress: '10040 W 27th Ave',
          addressLocality: 'Wheat Ridge',
          addressRegion: 'CO',
          postalCode: '80215',
          addressCountry: 'US',
        }}
        geo={{
          latitude: '39.75518599999999',
          longitude: '-105.1108284',
        }}
        images={[
          'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fguesthouse-social-default.jpg?alt=media',
        ]}
        sameAs={[
          'https://facebook.com/guesthouseshop',
          'https://instagram.com/guesthouseshop',
          'https://pinterest.com/guesthouseshop',
        ]}
      />

      <ContentBlock
        margin={[0, 4]}
      >
        <HeroAlt />
      </ContentBlock>

      <ContentBlock
        maxWidth="734px"
        margin={md ? [12, 0] : [0, 0]}
      >
        <Box style={{ width: '100%', textAlign: 'center' }}>
          <Typography className={classes.introCopy}>
            Guest House is your secret sauce to sell your home faster and for more money. With our easy-to-use tech platform and team of design experts, we deliver incredible staging in just a few days.
          </Typography>
        </Box>
      </ContentBlock>

      <ContentBlock>

      </ContentBlock >
      <ContentBlock
        maxWidth="none"
        margin={[0]}
        style={{
          backgroundColor: '#e1e7e8',
          background: 'linear-gradient(to top, rgba(247, 247, 247, 0) 20%, #e1e7e8)'
        }}
      >
        <ContentBlock
          maxWidth={1365}
          margin={[0, 'auto']}
          style={{ height: md ? 'auto' :140 }}
        >
          <div style={{ position: 'relative', top: md ? -175 : -142 }}>
            <Typography
              fontFamily={theme.gh_vars.circular}
              variant="h3Alt"
              style={{ textAlign: 'center', marginBottom: theme.spacing(4.5), fontSize: md ? 24 : 18 }}
            >
              Just a few of our brand partners 😍
            </Typography>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              {brandLogos.map((brand, i) => (
                <Grid
                  key={i}
                  item
                  md={2}
                  xs={brandLogos.length - i > 2 ? 4 : 6}
                >
                  <div className={classes.brandContainer}>
                    <Box
                      display="flex"
                      style={{
                        overflow: 'hidden',
                        height: brand.height,
                        width: '100%',
                        position: 'relative',
                        alignItems: 'center',
                      }}
                    >
                      <Image
                        fill
                        src={brand.logo}
                        alt={brand.name}
                        sizes="100vw"
                        style={{
                          objectFit: 'contain'
                        }}
                      />
                    </Box>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </ContentBlock>
      </ContentBlock>

      <ContentBlock margin={[0]}>
        <div style={{ width: '100%', height: md ? 700 : 434, overflow: 'hidden', position: 'relative', borderRadius: theme.shape.borderRadius, marginTop: md ? 0 : theme.spacing(6.5) }}>
          <Image
            fill
            src="/images/homepage/custom-design.png"
            alt=""
            sizes="100vw"
            style={{
              objectFit: 'cover'
            }}
          />
          <div className={classes.subHeroTextContainer}>
            <Typography
              fontFamily={theme.gh_vars.circular}
              variant="h2"
              component="h3"
              style={{ marginBottom: md ? theme.spacing(3) : theme.spacing(1) }}
            >
              Staging that&apos;s tech-enabled,
              <br />
              designer-led.
            </Typography>
            <Typography style={{ fontSize: md ? 16 : 13, lineHeight: 1.5, fontWeight: 400 }}>
              Choose your date online, checkout and work with our talented designers from start to finish. We make staging as simple as snapping your fingers.
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              component={ButtonLink}
              href="/home-staging"
              size="medium"
              className={classes.learnStagingButton}
            >
              Learn about staging
            </Button>
        
          </div>
        </div>
      </ContentBlock>

      <ContentBlock margin={[0, 0]}>
        <Box
          display="flex"
          flexDirection={md ? 'row' : 'column'}
          style={{ marginTop: theme.spacing(15) }}
        >
          <div style={{ width: md ? '50%' : '100%' }}>
            <div style={{ padding: md ? theme.spacing(6, 11, 6, 0) : theme.spacing(3, 6, 3, 0), backgroundColor: theme.palette.common.white, borderRadius: theme.spacing(0, 7.5, 7.5, 0) }}>
              <div style={{ width: lg ? 580 : md ? 400 : '100%', height: md ? 580 : sm ? 560 : 351, position: 'relative', overflow: 'hidden' }}>
                <Image
                  fill
                  src={'/images/homepage/platform-preview.png'}
                  alt=""
                  sizes="100vw"
                  style={{
                    objectFit: 'cover'
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: md ? '50%' : '100%', marginLeft: md ? theme.spacing(17) : 0, textAlign: md ? 'left' : 'center', marginTop: md ? 0 : theme.spacing(5.75) }}>
            <Typography
              variant="h3Alt"
              component="h3"
              className={classes.infoHeader}
              style={{ marginTop: md ? theme.spacing(7) : theme.spacing(0) }}
            >
              Instantly book photos, 3D tours & more
            </Typography>
            <Typography
              className={classes.infoCopy}
              style={{ width: md ? '80%' : '100%' }}
            >
              Guest House allows you to book all of your listing needs in one place. Simplify your day and leave the project management to us.
            </Typography>
            <Button
              variant="contained"
              component={ButtonLink}
              href="/home-staging/get-a-quote"
              size="large"
              style={{
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                width: md ? 312 : '100%',
                marginTop: md ? theme.spacing(6) : theme.spacing(4.5)
              }}
              onClick={() => {
                try {
                  window.analytics?.track('Staging Quote Clicked', {
                    properties: {
                      location: 'Header'
                    }
                  });
                } catch (e) {
                  Sentry.captureException(e);
                }
              }}
            >
              Get an instant quote
            </Button>
          </div>
        </Box>

        <Box
          display="flex"
          flexDirection={md ? 'row' : 'column'}
          justifyContent="space-between"
        >
          <div style={{ order: md ? 2 : 1, textAlign: 'center', position: 'relative', marginTop: md ? -100 : theme.spacing(14) }}>
            <Image
              src="/images/homepage/design-furnish-cropped.png"
              width={lg ? '598' : '500'}
              height={lg ? '779' : '680'}
              alt=""
              style={{
                maxWidth: '100%',
                height: 'auto'
              }}
            />
          </div>
          <div style={{ width: md ? '50%' : '100%', order: md ? 1 : 2, marginTop: md ? theme.spacing(15) : theme.spacing(5.5), textAlign: md ? 'left' : 'center' }}>
            <Typography
              variant="h3Alt"
              className={classes.infoHeader}
            >
              Get help furnishing your&nbsp;next&nbsp;home
            </Typography>
            <Typography
              className={classes.infoCopy}
              style={{ width: md ? '80%' : '100%' }}
            >
              Yes – we do interior design, too. Get a personalized selection of furniture and decor options at the best prices from our favorite brands.
            </Typography>
            <Button
              variant="contained"
              component={ButtonLink}
              href="/design"
              size="large"
              style={{
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                width: md ? 312 : '100%',
                marginTop: md ? theme.spacing(6) : theme.spacing(4.5)
              }}
              onClick={() => {
                try {
                  window.analytics?.track('Staging Quote Clicked', {
                    properties: {
                      location: 'Header'
                    }
                  });
                } catch (e) {
                  Sentry.captureException(e);
                }
              }}
            >
              Start an online consult
            </Button>
          </div>
        </Box>
      </ContentBlock>

      <ContentBlock
        maxWidth="none"
        margin={[0]}
        padding={[0]}
      >
        <div style={{ marginTop: md ? 0 : theme.spacing(15), height: 400 }}>
          <div style={{ height: 400, width: '100%', position: 'relative', marginTop: md ? theme.spacing(-2) : 0 }}>
            <Image
              fill
              src="/images/homepage/affirm-bg.png"
              alt=""
              sizes="100vw"
              style={{
                objectFit: 'cover'
              }}
            />
          </div>
          <div style={{ textAlign: 'center', width: '100%', height: '100%', position: 'relative', top: -300, zIndex: 2 }}>
            <IconAffirm style={{ height: md ? 60 : 40, width: md ? 152 : 100, marginBottom: theme.spacing(3) }} />
            <Typography
              fontFamily={theme.gh_vars.circular}
              display="block"
              variant="h3Alt"
              component="h2"
              style={{ marginBottom: theme.spacing(3), width: md ? '100%' : 228, marginLeft: 'auto', marginRight: 'auto' }}
            >
              Pay at your own pace&nbsp;with Affirm
            </Typography>
            <Typography style={{ width: md ? 715 : '100%', margin: 'auto', fontSize: md ? 16 : 13, lineHeight: 1.5, fontWeight: 400, paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }}>

              When you pay with Affirm, you always know exactly what you&apos;ll owe and your payment schedule. You’ll never pay hidden fee – or any fee – for that matter.
              {' '}
              <Link
                href="/affirm"
                style={{ textDecoration: 'none' }}
              >

                <mark style={{ backgroundColor: '#ffc300', padding: theme.spacing(0.25), borderRadius: 4 }}>
                  Just select Affirm&nbsp;at&nbsp;checkout.
                </mark>

              </Link>
            </Typography>
          </div>
        </div>
      </ContentBlock>

      <ContentBlock
        margin={[0, 'auto', 10]}
        maxWidth={1365}
        style={{ marginTop: md ? theme.spacing(15) : theme.spacing(8) }}
      >
        <Box style={{ width: '100%', textAlign: 'center' }}>
          <Typography
            gutterBottom
            variant="h2"
            style={{ marginBottom: theme.spacing(11) }}
          >
            Featured in 👀
          </Typography>
        </Box>

        <PressLogos />
      </ContentBlock>
    </>
  );
}

export default Homepage;
